import { useEffect } from 'react';
import { Head, useForm } from '@inertiajs/react';
import Heading from '@/components/atoms/heading';
import Button from '@/components/atoms/button';
import Checkbox from '@/components/molecules/checkbox';
import Text from '@/components/atoms/text';
import Link from '@/components/atoms/link';
import FormItem from '@/components/molecules/formItem';
import WebsiteAuthenticatedLayout from '@/Layouts/WebsiteAuthenticatedLayout';
import Alert from '@/components/molecules/alert';

export default function Login({ auth, resetAlert=false }) {
	const { data, setData, post, processing, errors, reset } = useForm({
		email: '',
		password: '',
		remember: false,
	});

	useEffect(() => {
		return () => {
			reset('password');
		};
	}, []);

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();

			post(route('login'));
			if (document.getElementById('submitBtn')) document.getElementById('submitBtn').focus();
		}
	};
	const submit = (e) => {
		e.preventDefault();
		console.log('Login...');

		post(route('login'));
	};

	return (
		<WebsiteAuthenticatedLayout showBanner={false} fullwidth={false} className="bg-eis-light-blue-1">
			<div className="flex flex-row justify-between -mx-6 sm:mx-0 pt-eis-65 sm:py-eis-108">
				<form
					onKeyDown={handleKeyDown}
					onSubmit={submit}
					className="w-full px-6 mx-auto bg-white sm:max-w-xl sm:rounded-lg py-eis-72 md:px-10 lg:px-14 xl:px-eis-72"
				>
					<div className="flex flex-col justify-between h-full gap-y-14">
						<Heading type="h4">Sign in</Heading>

						{ resetAlert && <Alert
							type="info"
							title="Reset link sent"
							description="If this email address is already registered, instructions on how to reset your password will be on their way to your inbox. Just click the link in the email to proceed."
						/> }

						<div className="flex flex-col gap-y-6">
							<FormItem
								type="email"
								name="email address"
								label="Email address"
								placeholder="Email address"
								value={data.email}
								onChange={(value) => setData((data) => ({ ...data, email: value }))}
								autoComplete="email"
								meta={{
									msg: errors.email,
									touched: errors.email ? true : false,
								}}
							/>

							<FormItem
								type="password"
								name="password"
								label="Password"
								placeholder="Password"
								value={data.password}
								onChange={(value) => setData((data) => ({ ...data, password: value }))}
								autoComplete="password"
								meta={{
									msg: errors.password,
									touched: errors.password ? true : false,
								}}
							/>

							<div className="flex flex-row flex-wrap items-center justify-between gap-2">
								<FormItem
									name="lgoin_remember"
									type="checkbox"
									label="Stay logged in"
									htmlFor="lgoin_remember"
									validation="required"
									disabled={false}
									value={data.remember}
									onChange={(value) => setData((data) => ({ ...data, remember: value }))}
									hasFormItemTop={false}
								/>

								<p className="flex gap-1">
									<Text type="span" variant="bold">
										Forgot your
									</Text>
									<Link variant="dark" isExternal={false} href={route('password.request')} className="font-bold">
										password
									</Link>
								</p>
							</div>

							<Button type="submit" variant="primary" fullWidth="fullWidth" disabled={processing}>
								Sign in
							</Button>
						</div>
					</div>
				</form>
			</div>
		</WebsiteAuthenticatedLayout>
	);
}
